import "bootstrap/dist/js/bootstrap.bundle.min";
window.$ = require("jquery");
require("loaders.css/loaders.css.js");

window.ns = {};
window.ns.Forteng = {}

;(function ($) {
    let $inputs = $('.js-has-placeholder');

    $inputs.on('focus', function () {
        $('[for="' + this.id + '"]').hide();
    });
    $inputs.on('blur', function () {
        if (this.value === '') {
            $('[for=' + this.id + ']').show();
        }
    });
})(window.$);

;(function($, options) {
    let hasOpened = false;
    $(function() {
        $(options.toggleSelector).on('click', function (event) {
            let $el = $(this).next();
            $el.toggle();
            $(options.dropdownSelector).not($el).filter(':visible').hide();
            hasOpened = true;
            event.stopPropagation();
            return false;
        });

        $(document).on('click', function (event) {
            if (hasOpened) {
                if ($(event.target).closest(options.dropdownSelector).length) {
                    event.stopPropagation();
                } else {
                    $(options.dropdownSelector).hide();
                    hasOpened = false;
                }

            }
        });
    });
}(window.$, {
    toggleSelector:  '[data-dropdown="toggle"]',
    dropdownSelector: '[data-dropdown="menu"]',
}));

;(function(ns) {
    let searchRequest = function (query, callback) {
        return $.getJSON('/search', {'q': query, 'json': true}, callback)
    };
    ns.searchRequest = searchRequest;
}(ns, $));


;(function ($, options) {
    $(function() {
        let $control = $(options.controlSelector),
            $result = $(options.resultSelector),
            timeOutId = null,
            hasOpened = false
        ;
        $control.on('input', function(event) {
            let $this = $(this);
            if ($this.val().length >= options.startLength){
                timeOutId && clearTimeout(timeOutId);
                timeOutId = setTimeout(function() {
                    $result.show().html('<div class="' + options.loadingElementClass + '"></div>');
                    $('.loader-inner').loaders();
                    hasOpened = true;
                    options.searchRequest($this.val(), function(data) {
                        if (typeof data === "string") {
                            $result.show().html(data);
                        } else {
                            let template = require("./templates/quick_search_results.twig");
                            let html = template({items: data});
                            $result.show().html(html);
                        }
                    } ).fail(function () {
                        $result.hide();
                        hasOpened = false;
                    });
                }, options.timeout)
            }
        });

        $(document).on('click', function (event) {
            if (hasOpened) {
                let exclude = options.resultSelector + ', ' + options.controlSelector;
                if ($(event.target).is(exclude) || $(event.target).closest(exclude).length) {
                    event.stopPropagation();
                } else {
                    $(options.resultSelector).hide();
                    hasOpened = false;
                }

            }
        });
    });

}(
    window.$,
    {
        controlSelector: '[data-live_search="control"]',
        resultSelector: '[data-live_search="results"]',
        loadingElementClass: ' loader-inner ball-pulse loader-animation',
        startLength: 3,
        timeout: 500,
        searchRequest: ns.searchRequest,
    }
));

